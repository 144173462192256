import { IConfig } from './IConfig';
/* tslint:disable */
/**
 * WARNING
 *
 * This file is generated at build time and overwrites the hard-coded.
 * local development config.
 * Do not edit, changes will not be persisted.
 */

let config: IConfig = {
  "access": {
    "appVersion": "57ef7d893b71e4fa6b782bf609115ed1cd1e69ec",
    "appName": "CheckAdmin",
    "appSlug": "check",
    "endpointV2": "https://api.access.staging.intouchinsight.com/v2",
    "endpointV3": "https://api.access.staging.intouchinsight.com/v3",
    "subscriptionUrl": "https://access.staging.intouchinsight.com/#/settings/subscriptions",
    "endpoint": "https://api.access.staging.intouchinsight.com/v1",
    "logoutUrl": "https://access.staging.intouchinsight.com/#/logout/IntouchCheck?site=admin",
    "organizationUrl": "https://access.staging.intouchinsight.com/#/organization/select/IntouchCheck",
    "loginUrl": "https://access.staging.intouchinsight.com/#/login/IntouchCheck?site=admin"
  },
  "sentry": {
    "dsn": "https://9f2c38a36d9e45f783587aad73074c1f@sentry.io/233284",
    "release": "57ef7d893b71e4fa6b782bf609115ed1cd1e69ec",
    "environment": "staging"
  },
  "tracker": {
    "pollInterval": 10
  },
  "products": {
    "apis": {
      "iq": {
        "version": "v1",
        "trace": true,
        "endpoint": "https://api.intelligence.staging.intouchinsight.com"
      },
      "intelligence": {
        "version": "v1",
        "trace": true,
        "endpoint": "https://api.intelligence.staging.intouchinsight.com",
        "url": "https://api.intelligence.staging.intouchinsight.com"
      },
      "check": {
        "version": "v2",
        "url": "https://api.check.staging.intouchinsight.com"
      },
      "templateLibraryApi": {
        "endpoint": "https://templates.staging.intouchinsight.com"
      }
    },
    "urls": {
      "check": {
        "app": "https://audit.check.staging.intouchinsight.com/#/login/callback/",
        "admin": "https://admin.check.staging.intouchinsight.com/#/login/callback/"
      },
      "intelligence": {
        "app": "https://intelligence.staging.intouchinsight.com/#/login/callback/"
      },
      "access": {
        "root": "https://access.staging.intouchinsight.com/#/",
        "app": "https://access.staging.intouchinsight.com/#/login/callback/"
      },
      "capture": {
        "admin": "https://admin.capture.staging.intouchinsight.com/#/sign-in/callback/"
      },
      "survey": {
        "admin": "https://admin.survey.staging.intouchinsight.com/#/login/callback/"
      }
    }
  },
  "secureFileApi": {
    "uploadUri": "https://api.check.staging.intouchinsight.com/v2/media/upload",
    "fileInfoUri": "https://api.staging.intouchinsight.com/services/file/v1/files"
  },
  "gtm": {
    "gtmPreview": "env-11",
    "id": "GTM-W4FJ5CZ",
    "gtmAuth": "fix17Ix9sLprDAmAmaCj5w"
  },
  "storage": {
    "keyPrefix": "itc"
  },
  "froala": {
    "key": "Ne2C1sA3E4I3B16B7A8jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6D5E1E4B3E1A9D9=="
  },
  "amMoment": {
    "locale": "en"
  },
  "api": {
    "trace": true,
    "checklistRequirementVersion": 4,
    "version": "v2",
    "endpoint": "https://api.check.staging.intouchinsight.com"
  },
  "updater": {
    "pollInterval": 10
  },
  "datadog": {
    "version": "57ef7d893b71e4fa6b782bf609115ed1cd1e69ec",
    "service": "check-admin",
    "rumSampleRate": 100,
    "env": "staging",
    "logSampleRate": 100,
    "applicationId": "af30bd98-2e68-4e6c-bc06-f93b86385f27",
    "clientToken": "pub11dbaca311403a87ca9fd7a62a92384f"
  },
  "toaster": {
    "position": "top left",
    "translate": true
  },
  "zendeskKey": "e2c6768d-b85e-4ab6-b547-facbbdc2f43a",
  "logger": {
    "level": 1
  },
  "session": {
    "snakeToCamelConvert": true
  },
  "dashboard": {
    "pollInterval": 2
  },
  "checkSession": {
    "appUrl": "https://audit.check.staging.intouchinsight.com/#/login/callback/"
  },
  "gaAll": {
    "token": "UA-117932154-10",
    "domain": "none"
  },
  "ga": {
    "token": "UA-117932154-2",
    "domain": "none"
  },
  "checkAppUrls": {
    "audits": "https://audit.check.staging.intouchinsight.com/#/audits/dashboard",
    "followups": "https://audit.check.staging.intouchinsight.com/#/followups/list",
    "checklists": "https://audit.check.staging.intouchinsight.com/#/checklists/list"
  }
};

export default config;

